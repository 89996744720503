
import Vue from "vue";

import store from "@/store";
import { Store, Admin, Breadcrumb } from "@/interfaces";
import http from "@/http";

export interface DataType {
  stores: number[];
}

export default Vue.extend({
  data(): DataType {
    return {
      stores: []
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "オプション",
          disabled: true
        }
      ];
    },
    allStores() {
      return store.state.stores;
    }
  },
  mounted() {
    this.stores = [
      ...store.getters.stores.map((store: Store) => {
        return store.id;
      })
    ];
  },
  methods: {
    async click() {
      await http.post<Admin>("admins", {
        stores: this.stores
      });
    }
  }
});
